const headerClass = () => {
    $(document.body).css('margin-top', $('header').outerHeight());

    const scroll = $(window).scrollTop();
    if (scroll <= 100) {
        $('header').removeClass('header-scrolled');
    } else {
        $('header').addClass('header-scrolled');
    }
};

$(window).resize(headerClass);
$(window).scroll(headerClass);

headerClass();
 
