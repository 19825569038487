import 'jquery-bez';

const backToTop = () => {
    $(window).scroll(function() {
        if ($(this).scrollTop() > 100) {
            $('.scrollup').fadeIn();
        } else {
            $('.scrollup').fadeOut();
        }
    });

    $('.scrollup').click(function() {
        $("html, body").animate({
            scrollTop: 0
        }, 600);
        return false;
    });
}

const scrollDown = () => {
    $('#scrolldown').click(function() {

        var scrolldownTarget = $('#scrolldown-target');
        $('html, body').animate({scrollTop: $( scrolldownTarget ).offset().top * 0.75 }, {
            duration: "300",
            easing: $.bez([.42, 0, 1, 1]),
            complete: function() {
                $('html, body').animate({scrollTop: ($( scrolldownTarget ).offset().top - $('header').height()) + 1 }, 'slow', $.bez([0,0,.58,1]));
            },
        });
    });
}

$(document).ready(() => {
    backToTop();
    scrollDown();
});
